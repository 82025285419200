import React, { Component } from 'react'
import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements'

class CardSection extends Component {
  render() {
    return (
        <label>
          <div>
            <label>Credit card number</label>
            <CardNumberElement placeholder={'0000 0000 0000 0000'} classes={{base: 'stripeInput', invalid: 'stripeInvalid'}} style={{base: {fontSize: '16px'}}} />
          </div>
          <div>
            <label>Expiry date</label>
            <CardExpiryElement classes={{base: 'stripeInput', invalid: 'stripeInvalid'}} style={{base: {fontSize: '16px'}}} />
          </div>
          <div>
            <label>CVC</label>
            <CardCVCElement classes={{base: 'stripeInput', invalid: 'stripeInvalid'}} style={{base: {fontSize: '16px'}}} />
          </div>
        </label>
    );
  }
};

export default CardSection
