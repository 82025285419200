import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Checkbox extends Component {
  constructor(props) {
    super(props)

    const checked = props.defaultChecked

    this.state = {
      isChecked: checked
    }
  }


  toggleCheckboxChange = () => {
    const { handleCheckboxChange, id, price, alwaysChecked } = this.props

    this.setState(({ isChecked }) => (
        {
          isChecked: (alwaysChecked ? true : !isChecked),
        }
    ))

    handleCheckboxChange(id, price)
  }

  render() {
    const { label, id } = this.props
    const { isChecked } = this.state

    return (
        <div className="input-checkbox">
          <input
              id={id}
              type="checkbox"
              value={label}
              checked={isChecked}
              onChange={this.toggleCheckboxChange}
          />
          <label htmlFor={id}>{label}</label>
        </div>
    );
  }
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
}

export default Checkbox
