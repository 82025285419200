import React, { Component } from 'react'
import { injectStripe } from 'react-stripe-elements'
import $ from 'jquery'
import CardSection from './cardSection'
import Config from '../config';

class CheckoutForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      success: false,
      processing: false,
      errorMessage: '',
    }
  }

  handleSubmit = ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault()

    this.setState({
      processing: true
    })

    let self = this

    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    this.props.stripe.createToken({ name: this.props.child }).then(result => {
      //console.log('Stripe result:', result);
      if (result.error) {
        self.setState({
          success: false,
          errorMessage: result.error.message,
          processing: false,
        });
        return
      }
      else {
        const data = {
          token: result.token.id,
          child: this.props.child,
          parent: this.props.parent,
          total: this.props.total,
          email: this.props.email,
        }
        $.ajax({
          url: `${Config.wpURL}/wp-content/plugins/smarty-party/pay.php`,
          dataType: 'json',
          type: 'POST',
          data: data,
          success: function(data, textStatus, xhr) {
            switch (data.httpStatus) {
              case 200:
                self.setState({
                  success: true
                })
                self.props.saveBooking()
                self.props.updateDepositPaid()
                self.props.emailCustomer()
                break
              default:
                self.setState({
                  success: false,
                  errorMessage: data.jsonBody.error.message,
                  processing: false,
                })
                break
            }
          },
          error: function(xhr, status, err) {
            self.setState({
              success: false,
              errorMessage: 'Sorry, something went wrong. Please try again. Your credit card has not been charged.',
              processing: false,
            })
          }
        })
      }
    })
  }

  render() {
    return (
        <div>
          {
            this.state.success === true &&
                <div className="successfulPayment">
                  <p>Payment has been successfully received. Thank you.</p>
                  <p>Hit <strong>Let's Party</strong> below to confirm your booking.</p>
                </div>
          }

          {
            this.state.success === false && (
                <div>
                  <form onSubmit={this.handleSubmit}>
                    <CardSection />
                    {
                      this.state.processing === true &&
                          <p>Processing...</p>
                    }

                    {
                      this.state.processing === false &&
                          <button className="btn btn-next btn-primary btn-lg stripePay">Pay now</button>
                    }
                  </form>
                  <div className="form-error">
                    {this.state.errorMessage}
                  </div>
                </div>
            )
          }
        </div>
    );
  }
}

export default injectStripe(CheckoutForm)
