import React, { Component } from 'react'
import Img from "gatsby-image"
import Modal from 'react-modal'
import Slider from 'react-slick'

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)'
  },
  content : {
    bottom: 'unset',
    maxWidth: '1000px',
    margin: '0 auto'
  }
}

class ThemeModal extends Component {
  closeModal = () => {
    this.props.setThemeModalVisible(!this.props.themeModalVisible)
  }

  render() {
    let sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: false
    }

    const item = this.props.item.node.acf

    return (
        <Modal
            isOpen={this.props.themeModalVisible}
            contentLabel={item.theme_name}
            style={customModalStyles}
            onRequestClose={() => this.props.setThemeModalVisible(!this.props.themeModalVisible)}
        >
          <div className="modal-wrapper">
            <div className="bm-cross-button">
              <span className="cross-container">
                  <span className="bm-cross left"></span>
                  <span className="bm-cross right"></span>
              </span>
              <button onClick={this.closeModal}>Close Menu</button>
            </div>
            <div className="modal-contents gallery">
              <Slider ref='slider' {...sliderSettings}>
                {
                  item.images.map((image, i) => (
                      <div key={i}>
                        <Img fluid={image.image.fullsize.childImageSharp.fluid} title={item.theme_name} alt={item.theme_name} />
                        <br /><br />
                        <p className="excerpt">{item.theme_name}</p>
                      </div>
                  ))
                }
              </Slider>
            </div>
          </div>
        </Modal>
    )
  }
}

export default ThemeModal
