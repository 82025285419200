import React, { Component } from 'react'
import { StripeProvider } from 'react-stripe-elements'
import axios from 'axios';
import $ from 'jquery'
import Checkout from './checkout'
import Img from 'gatsby-image'
import Link from "./link"
import Config from '../config'

class PartyStep4 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      one: true,
      two: false,
      recordID: 0,
      discountCode: '',
      discountCodeAmount: 0,
      discountCodeType: '',
      discountTotal: 0,
      invalidDiscount: false,
      validDiscount: false
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)

    this.props.updateStore({
      paymentMethod: 'Credit Card'
    })

    const dbStore = Object.assign({}, this.props.getStore()) //clone as to not update current Store

    if (Object.keys(this.props.getStore().partyPackage).length > 0) {
      dbStore.partyPackageName = this.props.getStore().partyPackage.name
      dbStore.partyPackagePrice = this.props.getStore().partyPackage.price
    }
    dbStore.partyThemeName = this.props.getStore().partyTheme.name
    dbStore.partyThemePrice = this.props.getStore().partyTheme.price
    dbStore.tentExtras = JSON.stringify(this.props.getStore().tentExtras)
    dbStore.sleepoverExtras = JSON.stringify(this.props.getStore().sleepoverExtras)
    dbStore.partyExtras = JSON.stringify(this.props.getStore().partyExtras)
    dbStore.partyDate = this.props.getStore().partyDate.format("YYYY-MM-DD HH:mm:ss")
    //dbStore.partyTime = this.props.getStore().partyTime.format("h:mma")
    dbStore.setupOption = JSON.stringify(this.props.getStore().setupOption)
    dbStore.delivery = JSON.stringify(this.props.getStore().delivery)
  }

  handleInputChange = e => {
    this.setState({
      discountCode: e.target.value,
      invalidDiscount: false,
    })
  }

  applyDiscount = () => {
    let self = this

    //get auth token
    let form = new FormData();
    form.append("username", "smarty");
    form.append("password", "p4rty");

    axios({
      method: 'POST',
      url: `${Config.wpURL}/wp-json/jwt-auth/v1/token`,
      data: form,
      headers: {'Content-Type': 'multipart/form-data'}
    })
        .then(function (response) {
          //handle success
          // console.log(response);
          self.applyDiscount2(response.data.token)
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
  }

  applyDiscount2 = token => {
    let self = this

    //get discount
    axios({
      method: 'GET',
      url: `${Config.wpURL}/wp-json/wp/v2/discounts?slug=${self.state.discountCode.toLowerCase()}`,
      headers: { 'Authorization': 'Bearer ' + token }
    })
        .then(function (response) {
          //handle success
          // console.log(response);
          if (response.data.length === 1) {
            //check if it is unused
            if(response.data[0].acf.used === "no") {
              let discountID = response.data[0].id
              let discountCodeType = response.data[0].acf.type
              let discountCode = response.data[0].acf.code
              let runningTotal = self.props.getStore().runningTotal
              let discountCodeAmount = parseInt(response.data[0].acf.amount)
              let discountTotal = self.props.getStore().runningTotal
              if (discountCodeType === 'dollars') {
                discountTotal = runningTotal - discountCodeAmount
              }
              else if (discountCodeType === 'percent') {
                discountTotal = runningTotal - (runningTotal * (discountCodeAmount/100))
              }

              self.setState({
                validDiscount: true,
                discountCode,
                discountCodeAmount,
                discountCodeType,
                discountTotal,
              })

              //update store that discount has been used
              self.props.updateStore({
                discountCode,
                discountCodeAmount,
                discountCodeType,
                discountTotal,
              })

              //update discounts post type that code has now been used
              self.applyDiscount3(token, discountID)
            }
            else {
              //already used, so invalid
              self.setState({ invalidDiscount: true })
            }
          }
          else {
            //more than one came back, so all were retrieved, OR, nothing came back, so it does not exist
            self.setState({ invalidDiscount: true })
          }
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
  }

  applyDiscount3 = (token, discountID) => {
    axios({
      method: 'PUT',
      url: `${Config.wpURL}/wp-json/acf/v3/posts/${discountID}`,
      data: {
        fields: {
          used: "yes"
        }
      },
      headers: { 'Authorization': 'Bearer ' + token }
    })
        .then(function (response) {
          //handle success
          //console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
  }

  // showMethodOne = e => {
  //   this.setState({
  //     one: true,
  //     two: false
  //   })
  //
  //   this.props.updateStore({
  //     paymentMethod: 'Credit Card'
  //   })
  // }

  // showMethodTwo = e => {
  //   this.setState({
  //     one: false,
  //     two: true
  //   })
  //
  //   this.props.updateStore({
  //     paymentMethod: 'Online Banking'
  //   })
  // }

  //save booking to WordPress database and calendar
  saveBooking = () => {
    let self = this

    //get auth token
    let form = new FormData();
    form.append("username", "smarty");
    form.append("password", "p4rty");

    axios({
      method: 'POST',
      url: `${Config.wpURL}/wp-json/jwt-auth/v1/token`,
      data: form,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(function (response) {
          //handle success
          // console.log(response);
          self.saveBooking2(response.data.token)
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
  }

  saveBooking2 = token => {
    let self = this

    //save organiser
    axios({
      method: 'POST',
      url: `${Config.wpURL}/wp-json/tribe/v1/organizers`,
      data: {
        organizer: `${this.props.getStore().parentFirstName} ${this.props.getStore().parentLastName}`,
        phone: this.props.getStore().parentPhone,
        email: this.props.getStore().parentEmail
      },
      headers: { 'Authorization': 'Bearer ' + token }
    })
        .then(function (response) {
          //handle success
          // console.log(response);
          self.setState({
            organizerID: response.data.id
          })
          self.saveBooking3(token, response.data.id)
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
  }

  saveBooking3 = (token, organiserID) => {
    let self = this

    //combine address line and suburb
    let address = this.props.getStore().partyAddress
    if (this.props.getStore().partySuburb !== '' && this.props.getStore().partySuburb !== undefined && this.props.getStore().partySuburb !== null) {
      address += ', ' + this.props.getStore().partySuburb
    }

    //save event with venue
    axios({
      method: 'POST',
      url: `${Config.wpURL}/wp-json/tribe/v1/events`,
      data: {
        title: `${this.props.getStore().childFirstName}'s party`,
        start_date: this.props.getStore().partyDate.format("YYYY-MM-DD 14:00:00"),
        end_date: this.props.getStore().partyDate.add(1, 'days').format("YYYY-MM-DD 10:00:00"),
        categories: ["Booking"],
        _ecp_custom_5: this.props.getStore().childFirstName,
        _ecp_custom_6: this.props.getStore().childLastName,
        _ecp_custom_7: this.props.getStore().childAge,
        _ecp_custom_9: this.props.getStore().gender,
        _ecp_custom_14: this.props.getStore().partyPackage.name,
        _ecp_custom_12: this.props.getStore().partyTheme.name,
        _ecp_custom_16: this.props.getStore().numberOfBeanBags ? this.props.getStore().numberOfBeanBags : this.props.getStore().numberOfTents,
        _ecp_custom_18: JSON.stringify(this.props.getStore().tentExtras),
        _ecp_custom_20: JSON.stringify(this.props.getStore().sleepoverExtras),
        _ecp_custom_22: JSON.stringify(this.props.getStore().partyExtras),
        _ecp_custom_24: this.props.getStore().setupOption.option,
        _ecp_custom_26: this.props.getStore().delivery.zone,
        _ecp_custom_28: this.props.getStore().hearabout,
        _ecp_custom_30: this.props.getStore().comments,
        _ecp_custom_32: this.props.getStore().runningTotal,
        _ecp_custom_34: this.props.getStore().discountCode,
        _ecp_custom_36: 'yes',
        cost: this.state.validDiscount ? `$${this.props.getStore().discountTotal}` : `$${this.props.getStore().runningTotal}`,
        venue: {
          venue: this.props.getStore().partyAddress,
          address: address,
          city: this.props.getStore().partyTownCity,
          country: "New Zealand"
        },
        organizer: [organiserID]
      },
      headers: { 'Authorization': 'Bearer ' + token }
    })
        .then(function (response) {
          //handle success
          //console.log(response)
        })
        .catch(function (response) {
          //handle error
          console.log(response)
        });
  }

  //update database with "depositPaid" = true
  updateDepositPaid = () => {
    this.props.updateStore({
      depositPaid: true
    })
  }

  emailCustomer = () => {
    const emailStore = Object.assign({}, this.props.getStore()) //clone as to not update current Store
    if (Object.keys(this.props.getStore().partyPackage).length > 0) {
      emailStore.partyPackageName = this.props.getStore().partyPackage.name
      emailStore.partyPackagePrice = this.props.getStore().partyPackage.price
    }
    emailStore.partyThemeName = this.props.getStore().partyTheme.name
    emailStore.partyThemePrice = this.props.getStore().partyTheme.price
    emailStore.tentExtras = JSON.stringify(this.props.getStore().tentExtras)
    emailStore.sleepoverExtras = JSON.stringify(this.props.getStore().sleepoverExtras)
    emailStore.partyExtras = JSON.stringify(this.props.getStore().partyExtras)
    emailStore.partyDate = this.props.getStore().partyDate.format("DD-MM-YYYY")
    //emailStore.partyTime = this.props.getStore().partyTime.format("h:mma")
    emailStore.setupOption = JSON.stringify(this.props.getStore().setupOption)
    emailStore.delivery = JSON.stringify(this.props.getStore().delivery)

    //send email
    $.ajax({
      url: `${Config.wpURL}/wp-content/plugins/smarty-party/emailCustomer.php`,
      type: 'POST',
      data: emailStore
    })
  }

  isValidated = () => {
    //check if a payment option has been chosen
    if (this.props.getStore().paymentMethod === undefined) {
      return false
    }

    //check if payment was success if credit card was chosen
    if (this.props.getStore().paymentMethod === 'Credit Card' && this.props.getStore().depositPaid === false) {
      return false
    }
  }

  render() {
    let displaySpecialSummary = false
    let displayPackageSummary = false
    let displaySleepoverSummary = false
    let displayBeanBagSummary = false
    let displayDesignYourOwnSummary = false
    let displayHireItemsSummary = false
    let product

    if (this.props.getStore().special === 'Smarty midweek holiday special') {
      displaySpecialSummary = true
      product = 'Smarty midweek holiday special'
    }
    else if (Object.keys(this.props.getStore().partyPackage).length > 0) {
      displayPackageSummary = true
      product = this.props.getStore().partyPackage.name

      if (this.props.getStore().partyPackage.name.includes("bean bag package")) {
        displayBeanBagSummary = true
      }
      else {
        displaySleepoverSummary = true
      }
    }
    else if (Object.keys(this.props.getStore().partyTheme).length > 0) {
      displayDesignYourOwnSummary = true
      product = this.props.getStore().partyTheme.name + ' party'
    }
    else {
      displayHireItemsSummary = true
      product = 'selected party items'
    }

    //let deposit = (this.props.getStore().runningTotal / 2).toFixed(2);
    let deposit = 50

    //tent extras
    let tentExtrasText = ''
    if (Object.keys(this.props.getStore().tentExtras).length > 0) {
      this.props.getStore().tentExtras.forEach(function(value, i) {
        if (i === 0) {
          tentExtrasText += value.name
        }
        else {
          tentExtrasText += ', ' + value.name
        }
      });
    }

    //sleepover extras
    let sleepoverExtrasText = ''
    if (Object.keys(this.props.getStore().sleepoverExtras).length > 0) {
      this.props.getStore().sleepoverExtras.forEach(function(value, i) {
        if (i === 0) {
          sleepoverExtrasText += value.name
        }
        else {
          sleepoverExtrasText += ', ' + value.name
        }
      });
    }

    //party extras
    let partyExtrasText = ''
    if (Object.keys(this.props.getStore().partyExtras).length > 0) {
      this.props.getStore().partyExtras.forEach(function(value, i) {
        if (i === 0) {
          partyExtrasText += value.name
        }
        else {
          partyExtrasText += ', ' + value.name
        }
      });
    }

    let address = this.props.getStore().partyAddress + ', '
    if (this.props.getStore().partySuburb !== '' && this.props.getStore().partySuburb !== undefined && this.props.getStore().partySuburb !== null) {
      address += this.props.getStore().partySuburb + ', '
    }
    address += this.props.getStore().partyTownCity

    return (
        <div className="party-steps">
          <h3>What have I selected for my {product}?</h3>
          <p>All up your {product} is going to be ${this.props.getStore().runningTotal}.</p>
          {
            displayPackageSummary && (
                <h4>Your items</h4>
            )
          }
          {
            displayDesignYourOwnSummary && (
                <h4>Design your own details</h4>
            )
          }
          {
            displayHireItemsSummary && (
                <h4>Your items</h4>
            )
          }
          <ul>
            {
              displaySpecialSummary && (
                  <span>
                      <li className="disc">3x {this.props.getStore().partyTheme.name} themed tents</li>
                      <li className="disc">1x light box</li>
                      <li className="disc">3x LED twinkle lights</li>
                      <li className="disc">1x picnic basket filled with lunchtime treats (please be aware that food will be prepared in our family kitchens so are not in a position to supply for specific allergies)</li>
                  </span>
              )
            }

            {
              displaySleepoverSummary && (
                  <span>
                      <li className="disc">1x guest of honour tent</li>
                      <li className="disc">{this.props.getStore().numberOfTents - 1}x guest tents</li>
                      <li className="disc">Bedding (airbed, sheet and blanket, child must BYO pillow and sleeping bag)</li>
                      <li className="disc">Sleepover storage box</li>
                      <li className="disc">Name plaque (chalk supplied)</li>
                      <li className="disc">LED twinkle lights (batteries supplied)</li>
                  </span>
              )
            }

            {
              displayBeanBagSummary && (
                  <li className="disc">{this.props.getStore().numberOfBeanBags}x black bean bags</li>
              )
            }

            {
              displayPackageSummary && (
                  <span>
                    {
                      Object.keys(this.props.getStore().tentExtras).length > 0 && (
                          <span>
                              {this.props.getStore().tentExtras.map((tentExtra, i) => (
                                  <li key={i} className="disc">{tentExtra.name}</li>
                              ))}
                          </span>
                      )
                    }

                    {
                      Object.keys(this.props.getStore().sleepoverExtras).length > 0 && (
                          <span>
                              {this.props.getStore().sleepoverExtras.map((sleepoverExtra, i) => (
                                  <li key={i} className="disc">{sleepoverExtra.name}</li>
                              ))}
                          </span>
                      )
                    }

                    {
                      Object.keys(this.props.getStore().partyExtras).length > 0 && (
                          <span>
                              {this.props.getStore().partyExtras.map((partyExtra, i) => (
                                  <li key={i} className="disc">{partyExtra.name}</li>
                              ))}
                          </span>
                      )
                    }
                  </span>
              )
            }

            {
              displayDesignYourOwnSummary && (
                  <span>
                      <li><strong>Theme:</strong> {this.props.getStore().partyTheme.name}</li>
                      <li><strong>Number of tents:</strong> {this.props.getStore().numberOfTents}</li>
                      <li><strong>Styling extras:</strong> {tentExtrasText}</li>
                      <li><strong>Sleepover extras:</strong> {sleepoverExtrasText}</li>
                      <li><strong>Party extras:</strong> {partyExtrasText}</li>
                  </span>
              )
            }

            {
              displayHireItemsSummary && (
                  <span>
                      {this.props.getStore().partyExtras.map((partyExtra, i) => (
                          <li key={i} className="disc">{partyExtra.name}</li>
                      ))}
                  </span>
              )
            }
          </ul>
          <h4>Party details</h4>
          <ul>
            <li><strong>Child's name:</strong> {this.props.getStore().childFirstName} {this.props.getStore().childLastName}</li>
            <li><strong>Child's age:</strong> {this.props.getStore().childAge}</li>
            {
              this.props.getStore().gender && (
                  <li><strong>Child's gender:</strong> {this.props.getStore().gender}</li>
              )
            }
            <li><strong>Parent/Guardian's name:</strong> {this.props.getStore().parentFirstName} {this.props.getStore().parentLastName}</li>
            <li><strong>Parent/Guardian's phone number:</strong> {this.props.getStore().parentPhone}</li>
            <li><strong>Parent/Guardian's email:</strong> {this.props.getStore().parentEmail}</li>
            <li><strong>Party address: </strong> {address}</li>
            <li><strong>Party date:</strong> {this.props.getStore().partyDate.format("DD/MM/YYYY")}</li>
            {
              displayDesignYourOwnSummary && (
                  <li><strong>Setup option:</strong> {this.props.getStore().setupOption.option}</li>
              )
            }
            {
              displayHireItemsSummary && (
                  <li><strong>Setup option:</strong> {this.props.getStore().setupOption.option}</li>
              )
            }
            <li><strong>Delivery:</strong> {this.props.getStore().delivery.zone}</li>
            {
              (this.props.getStore().hearabout !== '' && this.props.getStore().hearabout !== undefined && this.props.getStore().hearabout !== null) && (
                  <li><strong>Where did you hear about us?: </strong> {this.props.getStore().hearabout}</li>
              )
            }
            {
              (this.props.getStore().comments !== '' && this.props.getStore().comments !== undefined && this.props.getStore().comments !== null) && (
                  <li><strong>Comments: </strong> {this.props.getStore().comments}</li>
              )
            }
          </ul>
          <p className="description">If there is anything wrong with your above order, please hit the Previous button below to go back and make a change.</p>

          <h3>Do you have a discount code?</h3>
          <div>
            {
              this.state.validDiscount && this.state.discountCodeType === 'dollars' && (
                  <div>
                    <label>Your discount of ${this.state.discountCodeAmount} off has successfully been applied.</label>
                    <label>Your {product} is now going to be ${this.state.discountTotal}.</label>
                  </div>
              )
            }
            {
              this.state.validDiscount && this.state.discountCodeType === 'percent' && (
                  <div>
                    <label>Your discount of {this.state.discountCodeAmount}% off has successfully been applied.</label>
                    <label>Your {product} is now going to be ${this.state.discountTotal}.</label>
                  </div>
              )
            }
            {
              !this.state.validDiscount && (
                  <div>
                    <label>Enter your discount code here, if you have one</label>
                    <input type="discount" name="discount" onChange={this.handleInputChange} />
                  </div>
              )
            }
            {
              this.state.invalidDiscount && (
                  <div className="form-error">Sorry, that's an invalid discount code.</div>
              )
            }
            {
              !this.state.validDiscount && (
                  <button className="btn btn-next btn-primary btn-lg discountApply" onClick={this.applyDiscount}>Apply</button>
              )
            }
          </div>

          <h3>What are your card details?</h3>
          {/*
              <p>Right now we'll need a 50% deposit, which for you today will be <strong>${deposit}</strong>.</p>
          */}
          <p>Right now we'll need a <strong>${deposit}</strong> deposit to secure your booking.</p>
          {/*
          <p>I would like to pay by:</p>

          <div className="radio">
              <input id="option1" type="radio" value="option1" checked={this.state.one} onChange={this.showMethodOne} />
              <label htmlFor="option1">Credit card</label>
          </div>
          */}

          {
            this.state.one && (
                <div>
                  <div className="payment-method credit-card">
                    <div className="stripeCard">
                      {/* pk_test_51H8x6yDJ4c2NerA6FzZ8F2B2GBBhBfXfsg4jKVwrFcEWwLHE5NkzmcuhLT6MHsJOCxWwjCPOPeFlnvlmC9ZyGaLp00hlUCu1Ov */}
                      {/* pk_live_51H8x6yDJ4c2NerA6XOKcgmmjSpazz6utnivDa7xR83tf5wBesJGWC601K9lIA6XmJHswRckDM9lcC3CFofZ1DZMg00BawKDthw */}
                      <StripeProvider apiKey="pk_live_51H8x6yDJ4c2NerA6XOKcgmmjSpazz6utnivDa7xR83tf5wBesJGWC601K9lIA6XmJHswRckDM9lcC3CFofZ1DZMg00BawKDthw">
                        <Checkout
                            child={`${this.props.getStore().childFirstName} ${this.props.getStore().childLastName}`}
                            total={this.props.getStore().runningTotal}
                            email={this.props.getStore().parentEmail}
                            parent={`${this.props.getStore().parentFirstName} ${this.props.getStore().parentLastName}`}
                            updateStore={(u) => {this.props.updateStore(u)}}
                            saveBooking={() => {this.saveBooking()}}
                            updateDepositPaid={() => {this.updateDepositPaid()}}
                            emailCustomer={() => {this.emailCustomer()}}
                        />
                      </StripeProvider>
                    </div>
                    <div className="stripeDetails">
                      <div className="stripeDetailsWrapper">
                        <p className="description">We use Stripe for payment, analytics, and other business services. Stripe collects identifying information about the devices that connect to its services. Stripe uses this information to operate and improve the services it provides to us, including for fraud detection. You can learn more about Stripe and read its privacy policy at <Link href="https://stripe.com/privacy" rel="noopener noreferrer" target="_blank">https://stripe.com/privacy</Link>.</p>
                        <Img fixed={this.props.poweredByStripe.childImageSharp.fixed} title="Stripe logo" alt="Stripe logo" />
                      </div>
                    </div>
                    <div className="clear"></div>
                  </div>
                </div>
            )
          }

          {/*
          <div className="radio">
              <input id="option2" type="radio" value="option2" checked={this.state.two} onChange={this.showMethodTwo} />
              <label htmlFor="option2">Online banking</label>
          </div>
          */}

          {/*
            this.state.two && (
                <div className="payment-method online-banking">
                  <p>Online banking payment method details and bank account number goes here.</p>
                </div>
            )
          */}
        </div>
    );
  }
}

export default PartyStep4
