import React, { Component } from 'react'
import Img from "gatsby-image"
import NumericInput from 'react-numeric-input'
import _ from 'lodash'
import Checkbox from './checkbox'
import ThemeModal from './themeModal'
import StylingModal from './stylingModal'

class BeanBagStep1 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      themeModalVisible: false,
      themeModalContents: props.allThemes[0],
      stylingModalVisible: false,
      stylingModalContents: props.allThemes[0],
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  handleThemeChange = e => {
    const i = parseInt(e.target.value, 10)
    this.props.updateStore({
      partyPackage: {'name': `${this.props.allThemes[i].node.acf.theme_name} bean bag package`, 'price': this.props.qty === 3 ? parseInt(this.props.smarty3Price, 10) : parseInt(this.props.smarty5Price, 10)},
      partyTheme: {'index': i, 'name': this.props.allThemes[i].node.acf.theme_name, 'price': 0},
      tentExtras: this.getTentExtras(i),
      partyExtras: this.getPartyExtras(i),
    })
  }

  getTentExtras = i => {
    const tentExtras = []
    for (let j = 0; j < this.props.allThemes[i].node.acf.styling_items.length; j++) {
      if(this.props.allThemes[i].node.acf.styling_items[j].quantity === 'multiple') {
        tentExtras.push({'name': this.props.allThemes[i].node.acf.styling_items[j].item, 'price': 0})
      }
    }
    return tentExtras
  }

  getPartyExtras = i => {
    const partyExtras = [];
    for (let j = 0; j < this.props.allThemes[i].node.acf.styling_items.length; j++) {
      if(this.props.allThemes[i].node.acf.styling_items[j].quantity === 'single') {
        partyExtras.push({'name': this.props.allThemes[i].node.acf.styling_items[j].item, 'price': 0})
      }
    }
    return partyExtras
  }

  setThemeModalVisible = visible => {
    this.setState({
      themeModalVisible: visible
    })
  }

  openThemeModal = i => {
    this.setState({
      themeModalContents: this.props.allThemes[i]
    })
    this.setThemeModalVisible(true)
  }

  setStylingModalVisible = visible => {
    this.setState({
      stylingModalVisible: visible
    })
  }

  openStylingModal = i => {
    this.setState({
      stylingModalContents: this.props.allThemes[i]
    })
    this.setStylingModalVisible(true)
  }

  render() {
    const allThemes = this.props.allThemes

    return (
        <div className="party-steps package-steps">
          <h3>Choose your theme</h3>

          <h4>Select your theme</h4>
          <p className="helper">Each bean bag package comes complete with {this.props.qty} bean bags, styling accessories, and snacks ready for a relaxed and chilled out time whether watching a movie, playing games on a console, or enjoying pamper time with the girls.</p>

          <section className="lazy-load-box effect-slideup">
            <ul className="posts-grid row-fluid unstyled home-page-post-grid ul-item-0">
              {
                allThemes.map((item, i) => (
                    <li key={i} className={`span4 list-item-${i+1}`}>
                      <h4>{item.node.acf.theme_name}</h4>
                      <figure className="featured-thumbnail thumbnail">
                        <a onClick={() => this.openThemeModal(i)}>
                          <Img fluid={item.node.acf.images[0].image.thumb.childImageSharp.fluid} title={item.node.acf.theme_name} alt={item.node.acf.theme_name} />
                          <span className="zoom-icon"></span>
                        </a>
                      </figure>

                      <ThemeModal item={this.state.themeModalContents} themeModalVisible={this.state.themeModalVisible} setThemeModalVisible={this.setThemeModalVisible} />

                      <ul>
                        <li className="list-item-option">
                          <label className="package-label">Styling includes</label>
                          <div className="table">
                            <div className="tr">
                              <div className="td img styling-thumbnail">
                                <a onClick={() => this.openStylingModal(i)}>
                                  <Img fluid={item.node.acf.styling_image.thumb.childImageSharp.fluid} className="tent-extra" title={`${item.node.acf.theme_name} styling`} alt={`${item.node.acf.theme_name} styling`} />
                                  <span className="zoom-icon"></span>
                                </a>
                              </div>
                              <div className="td">
                                <div className="description">
                                  <ul>
                                    {
                                      item.node.acf.styling_items.map((style, s) => (
                                          <li key={s}>{`${style.quantity === 'multiple' ? this.props.qty : '1'}x`} {style.item}</li>
                                      ))
                                    }
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div className="clear"></div>
                      <div className="radio">
                        <input id={`theme${i+1}`} type="radio" name="theme" value={i} checked={this.props.getStore().partyPackage.name === `${item.node.acf.theme_name} bean bag package`} onChange={this.handleThemeChange} />
                        <label htmlFor={`theme${i+1}`}>{item.node.acf.theme_name} theme</label>
                      </div>

                      <StylingModal item={this.state.stylingModalContents} stylingModalVisible={this.state.stylingModalVisible} setStylingModalVisible={this.setStylingModalVisible} />
                    </li>
                ))
              }
            </ul>
          </section>
        </div>
    )
  }
}

export default BeanBagStep1
