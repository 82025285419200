import React, { Component } from 'react'
import Link from "./link"

class PartyStep5 extends Component {
  constructor (props) {
    super(props)
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render() {
    return (
        <div className="party-steps">
          <h3>Thanks!</h3>
          <p>Thank you for making a Smarty Party booking.</p>
          <p>You will receive a confirmation email from us shortly and a friendly reminder 24 hours before your final payment is deducted 7 days prior to your party date.</p>
          <p>If you require anything else please don't hesitate to <Link to="/contact">contact our team</Link>. In the meantime follow us on <Link target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/smartypartynz">Facebook</Link>, <Link target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/smartypartynz">Instagram</Link> and <Link target="_blank" rel="noopener noreferrer" href="https://www.pinterest.com/smartypartynz">Pinterest</Link> to keep in touch with all the exciting things happening at Smarty Party.</p>
        </div>
    )
  }
}

export default PartyStep5
