import React, { Component } from 'react'
import moment from 'moment'
import NumericInput from 'react-numeric-input'
import DatePicker from 'react-datepicker'
import Modal from 'react-modal'
import Checkbox from './checkbox'
import Img from "gatsby-image"

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)'
  }
}

class PartyStep3 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      modalIsOpen: false,
      deliveryModalIsOpen: false,
      invalidChildFirstName: false,
      invalidChildLastName: false,
      invalidChildAge: false,
      invalidGender: false,
      invalidParentFirstName: false,
      invalidParentLastName: false,
      invalidParentPhone: false,
      invalidParentEmail: false,
      invalidPartyAddress: false,
      invalidPartyTownCity: false,
      invalidTermsConditions: false
    }
  }

  componentWillMount = () => {
    this.selectedCheckboxes = new Map()
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  handleInputChange = e => {
    this.props.updateStore({
      [e.target.name]: e.target.value
    })
  }

  handleAge = valueAsNumber => {
    this.props.updateStore({
      childAge: valueAsNumber
    })
  }

  handleDateChange = date => {
    this.props.updateStore({
      partyDate: date
    })
    this.toggleCalendar()
  }

  toggleCalendar = e => {
    e && e.preventDefault()
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  toggleCheckbox = label => {
    if (this.selectedCheckboxes.has(label)) {
      this.selectedCheckboxes.delete(label)
    } else {
      this.selectedCheckboxes.set(label)
    }
  }

  chooseSetup = e => {
    this.props.updateStore({
      setupOption: {
        option: e.currentTarget.value,
        price: parseInt(e.currentTarget.getAttribute('data-price'), 10)
      }
    })
  }

  chooseDeliverySetup = e => {
    this.props.updateStore({
      delivery: {
        zone: e.currentTarget.value,
        price: parseInt(e.currentTarget.getAttribute('data-price'), 10)
      }
    })
  }

  chooseGender = e => {
    this.props.updateStore({
      gender: e.currentTarget.value
    })
  }

  openModal = () => {
    this.setState({
      modalIsOpen: true
    })
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false
    })
  }

  openDeliveryModal = () => {
    this.setState({
      deliveryModalIsOpen: true
    })
  }

  closeDeliveryModal = () => {
    this.setState({
      deliveryModalIsOpen: false
    })
  }

  isValidated = () => {
    if (this.props.getStore().childFirstName === undefined || this.props.getStore().childFirstName === '') {
      this.setState({ invalidChildFirstName: true })
      return false
    }

    if (this.props.getStore().childLastName === undefined || this.props.getStore().childLastName === '') {
      this.setState({ invalidChildLastName: true })
      return false
    }

    if (!this.props.getStore().partyPackage.name.includes("bean bag package")) {
      if (this.props.getStore().gender === undefined || this.props.getStore().gender === '') {
        this.setState({ invalidGender: true })
        return false
      }
    }

    if (this.props.getStore().childAge === null || this.props.getStore().childAge === '') {
      this.setState({ invalidChildAge: true })
      return false
    }

    if (this.props.getStore().parentFirstName === undefined || this.props.getStore().parentFirstName === '') {
      this.setState({ invalidParentFirstName: true })
      return false
    }

    if (this.props.getStore().parentLastName === undefined || this.props.getStore().parentLastName === '') {
      this.setState({ invalidParentLastName: true })
      return false
    }

    if (this.props.getStore().parentPhone === undefined || this.props.getStore().parentPhone === '') {
      this.setState({ invalidParentPhone: true })
      return false
    }

    if (this.props.getStore().parentEmail === undefined || this.props.getStore().parentEmail === '') {
      this.setState({ invalidParentEmail: true })
      return false
    }

    if (this.props.getStore().partyAddress === undefined || this.props.getStore().partyAddress === '') {
      this.setState({ invalidPartyAddress: true })
      return false
    }

    if (this.props.getStore().partyTownCity === undefined || this.props.getStore().partyTownCity === '') {
      this.setState({ invalidPartyTownCity: true })
      return false
    }

    if (!this.selectedCheckboxes.has('termsandconditions')) {
      this.setState({ invalidTermsConditions: true })
      return false
    }
  }

  render() {
    //filter out only unavailableDates in the events array
    //actually, unavailable dates and dates with bookings should both be excluded, so don't do this filter for now
    //let unavailableDates = this.props.allEvents[0].node.events.filter(e => e.categories[0].name === "Unavailable")
    let unavailableDates = this.props.allEvents[0].node.events

    //make an array of only Moment date objects for the date picker
    let unavailableDatesArray = []
    unavailableDates.map(date => (
        unavailableDatesArray.push(moment(date.start_date))
    ))

    return (
        <div className="party-steps">
          <h3>What are your details?</h3>

          <h4>Who is the guest of honour?</h4>
          <div>
            <label>Child's name</label>
            <input type="text" name="childFirstName" placeholder="First name" value={this.props.getStore().childFirstName} onChange={this.handleInputChange} />
            <input type="text" className="second-row" name="childLastName" placeholder="Last name" value={this.props.getStore().childLastName} onChange={this.handleInputChange} />
            {
              this.state.invalidChildFirstName &&
                  <div className="form-error">This field is required.</div>
            }
            {
              this.state.invalidChildLastName &&
                  <div className="form-error second-row-error">This field is required.</div>
            }
            <p className="description">We need to know their name so we know who gets the special tent.</p>
          </div>
          <div>
            <label>Child's age</label>
            <div className="input-number">
              <NumericInput
                  className="child-age"
                  min={5}
                  max={15}
                  value={this.props.getStore().childAge}
                  onChange={this.handleAge}
              />
            </div>
            {
              this.state.invalidChildAge &&
                  <div className="form-error">This field is required.</div>
            }
            <p className="description">We need to know the age of your child so we can style appropriately. If it's for a birthday, put what age they'll be turning.<br />Minimum age of 5, maximum age of 15.</p>
          </div>
          {
            !this.props.getStore().partyPackage.name.includes("bean bag package") &&
                <div>
                  <label>Child's gender</label>
                  <div className="radio">
                    <input id="male" name="gender" type="radio" value="Male" checked={this.props.getStore().gender === 'Male'} onChange={this.chooseGender} />
                    <label htmlFor="male">Male</label>
                  </div>
                  <div className="radio">
                    <input id="female" name="gender" type="radio" value="Female" checked={this.props.getStore().gender === 'Female'} onChange={this.chooseGender} />
                    <label htmlFor="female">Female</label>
                  </div>
                  {
                    this.state.invalidGender && (
                        <div className="form-error">This field is required.</div>
                    )
                  }
                  <p className="description">Please let us know the gender of your guest of honour so our styling packages are suitable.</p>
                </div>
          }

          <h4>Who is organising this party?</h4>
          <div>
            <label>Parent/Guardian's name</label>
            <input type="text" name="parentFirstName" placeholder="First name" value={this.props.getStore().parentFirstName} onChange={this.handleInputChange} />
            <input type="text" className="second-row" name="parentLastName" placeholder="Last name" value={this.props.getStore().parentLastName} onChange={this.handleInputChange} />
            {
              this.state.invalidParentFirstName &&
                  <div className="form-error">This field is required.</div>
            }
            {
              this.state.invalidParentLastName &&
                  <div className="form-error second-row-error">This field is required.</div>
            }
            <p className="description">Please ensure that the person making this booking is 18 years or older as per our T&Cs.</p>
          </div>
          <div>
            <label>Parent/Guardian's phone number</label>
            <input type="text" name="parentPhone" value={this.props.getStore().parentPhone} onChange={this.handleInputChange} />
            {
              this.state.invalidParentPhone &&
                  <div className="form-error">This field is required.</div>
            }
            <p className="description">So we can contact you.</p>
          </div>
          <div>
            <label>Parent/Guardian's email address</label>
            <input type="email" name="parentEmail" value={this.props.getStore().parentEmail} onChange={this.handleInputChange} />
            {
              this.state.invalidParentEmail &&
                  <div className="form-error">This field is required.</div>
            }
            <p className="description">So you can receive confirmation.</p>
          </div>

          <h3>What are the party details?</h3>
          <div>
            <label>Party address</label>
            <input type="text" name="partyAddress" placeholder="Number and street address" value={this.props.getStore().partyAddress} onChange={this.handleInputChange} />
            <input type="text" className="second-row" name="partySuburb" placeholder="Suburb" value={this.props.getStore().partySuburb} onChange={this.handleInputChange} />
            <input type="text" className="third-row" name="partyTownCity" placeholder="Town/City" value={this.props.getStore().partyTownCity} onChange={this.handleInputChange} />
            {
              this.state.invalidPartyAddress &&
                  <div className="form-error">This field is required.</div>
            }
            {
              this.state.invalidPartyTownCity &&
                  <div className="form-error third-row-error">This field is required.</div>
            }
            <p className="description">We need to know the address of where the party will be held as per our T&Cs and to ensure the delivery charge is correct.</p>
          </div>
          <div className="relative">
            <label>Party date</label>
            <input type="text" className="react-datepicker" onClick={this.toggleCalendar} value={this.props.getStore().partyDate.format("DD/MM/YYYY")} readOnly={true} />
            <span className="openMe">
                <Img onClick={this.toggleCalendar} fixed={this.props.calendarIcon.childImageSharp.fixed} title="Select date" alt="Select date" />
            </span>
            <p className="description">Our bookings require a minimum of 7 days notice, please contact us if you wish to book within 7 days for availability. Please note that delivery/setup time is 2:00pm and we require up to 2 hours for full setup. We return at 10:00am the next day to pack down.</p>
            {
              this.state.isOpen &&
                  <DatePicker
                      minDate={moment().add(7, 'days')}
                      //excludeDates={[moment('2019-07-04'), moment('2019-07-05'), moment('2019-07-07'), moment('2019-07-08'), moment('2019-07-09'), moment('2019-07-10'), moment('2019-07-11'), moment('2019-07-12'), moment('2019-07-14'), moment('2019-07-15'), moment('2019-07-16'), moment('2019-07-17'), moment('2019-07-18'), moment('2019-07-19'), moment('2019-07-21'), moment('2019-07-22'), moment('2019-07-23'), moment('2019-07-24'), moment('2019-07-25'), moment('2019-07-26'), moment('2019-07-28'), moment('2019-07-29'), moment('2019-07-30'), moment('2019-07-31')]}
                      excludeDates={unavailableDatesArray}
                      selected={this.props.getStore().partyDate}
                      onChange={this.handleDateChange}
                      withPortal
                      inline
                  />
            }
          </div>

          {
            Object.keys(this.props.getStore().partyPackage).length > 0 &&
                <div>
                  <h4>Delivery/Setup</h4>
                  <div className="radio">
                    <input id="delivery1" name="deliveryZones" type="radio" value="Zone 1" data-price="0" checked={this.props.getStore().delivery.zone === 'Zone 1'} onChange={this.chooseDeliverySetup} />
                    <label htmlFor="delivery1">Zone 1</label>
                    <p className="description">Includes Auckland central suburbs as far as Mt Wellington and selected East Auckland suburbs.</p>
                  </div>
                  <div className="radio">
                    <input id="delivery2" name="deliveryZones" type="radio" value="Zone 2" data-price="15" checked={this.props.getStore().delivery.zone === 'Zone 2'} onChange={this.chooseDeliverySetup} />
                    <label htmlFor="delivery2">Zone 2 (+$15)</label>
                    <p className="description">Includes selected West and South Auckland areas.</p>
                  </div>
                  <div className="radio">
                    <input id="delivery3" name="deliveryZones" type="radio" value="Zone 3" data-price="30" checked={this.props.getStore().delivery.zone === 'Zone 3'} onChange={this.chooseDeliverySetup} />
                    <label htmlFor="delivery3">Zone 3 (+$30)</label>
                    <p className="description">Includes North Shore as far as Silverdale and Helensville.</p>
                  </div>
                  <p><a className="terms smartyRed" onClick={this.openDeliveryModal}>View our delivery zones</a></p>
                </div>
          }

          {
            Object.keys(this.props.getStore().partyPackage).length === 0 && this.props.getStore().diy === false &&
                <div>
                  <h4>Delivery/Setup</h4>
                  <div className="radio">
                    <input id="option1" type="radio" value="Delivery + Setup" data-price="70" checked={this.props.getStore().setupOption.option === 'Delivery + Setup'} onChange={this.chooseSetup} />
                    <label htmlFor="option1">Delivery + Setup</label>
                    <p className="description">Full service – you simply book and pay. We do absolutely everything else - delivery, setup and style, and then come back the next morning to pack down and take away.</p>
                  </div>
                  <div className="radio">
                    <input id="option2" type="radio" value="Delivery + DIY" data-price="35" checked={this.props.getStore().setupOption.option === 'Delivery + DIY'} onChange={this.chooseSetup} />
                    <label htmlFor="option2">Delivery + DIY</label>
                    <p className="description">Create your package to your specific requirements, we simply deliver and pick up from your home but leave you to do the setup and pack down.</p>
                  </div>
                  <h4>Delivery zones</h4>
                  <div className="radio">
                    <input id="delivery1" name="deliveryZones" type="radio" value="Zone 1" data-price="0" checked={this.props.getStore().delivery.zone === 'Zone 1'} onChange={this.chooseDeliverySetup} />
                    <label htmlFor="delivery1">Zone 1</label>
                    <p className="description">Includes Auckland central suburbs as far as Mt Wellington and selected East Auckland suburbs.</p>
                  </div>
                  <div className="radio">
                    <input id="delivery2" name="deliveryZones" type="radio" value="Zone 2" data-price="15" checked={this.props.getStore().delivery.zone === 'Zone 2'} onChange={this.chooseDeliverySetup} />
                    <label htmlFor="delivery2">Zone 2 (+$15)</label>
                    <p className="description">Includes selected West and South Auckland areas.</p>
                  </div>
                  <div className="radio">
                    <input id="delivery3" name="deliveryZones" type="radio" value="Zone 3" data-price="30" checked={this.props.getStore().delivery.zone === 'Zone 3'} onChange={this.chooseDeliverySetup} />
                    <label htmlFor="delivery3">Zone 3 (+$30)</label>
                    <p className="description">Includes North Shore as far as Silverdale and Helensville.</p>
                  </div>
                  <p><a className="terms smartyRed" onClick={this.openDeliveryModal}>View our delivery zones</a></p>
                  <p className="description">Please select your delivery zone based on your address so we charge the correct fee. If your address is outside of our zones, please contact us direct before placing your booking to see if we can assist.</p>
                </div>
          }

          {
            Object.keys(this.props.getStore().partyPackage).length === 0 && this.props.getStore().diy === true && Object.keys(this.props.getStore().tentExtras).length > 0 &&
                <div>
                  <h4>Delivery/Setup</h4>
                  <div className="radio">
                    <input id="delivery1" name="deliveryZones" type="radio" value="Zone 1" data-price="0" checked={this.props.getStore().delivery.zone === 'Zone 1'} onChange={this.chooseDeliverySetup} />
                    <label htmlFor="delivery1">Zone 1</label>
                    <p className="description">Includes Auckland central suburbs as far as Mt Wellington and selected East Auckland suburbs.</p>
                  </div>
                  <div className="radio">
                    <input id="delivery2" name="deliveryZones" type="radio" value="Zone 2" data-price="15" checked={this.props.getStore().delivery.zone === 'Zone 2'} onChange={this.chooseDeliverySetup} />
                    <label htmlFor="delivery2">Zone 2 (+$15)</label>
                    <p className="description">Includes selected West and South Auckland areas.</p>
                  </div>
                  <div className="radio">
                    <input id="delivery3" name="deliveryZones" type="radio" value="Zone 3" data-price="30" checked={this.props.getStore().delivery.zone === 'Zone 3'} onChange={this.chooseDeliverySetup} />
                    <label htmlFor="delivery3">Zone 3 (+$30)</label>
                    <p className="description">Includes North Shore as far as Silverdale and Helensville.</p>
                  </div>
                  <p><a className="terms smartyRed" onClick={this.openDeliveryModal}>View our delivery zones</a></p>
                  <p className="description">As you have chosen one of our styling options, we do absolutely everything - delivery, tent setup and style, and then come back the next morning to pack down and take away.</p>
                </div>
          }

          {
            Object.keys(this.props.getStore().partyPackage).length === 0 && this.props.getStore().diy === true && Object.keys(this.props.getStore().tentExtras).length === 0 &&
                <div>
                  <h4>Delivery/Setup</h4>
                  <div className="radio">
                    <input id="delivery1" name="deliveryZones" type="radio" value="Zone 1" data-price="0" checked={this.props.getStore().delivery.zone === 'Zone 1'} onChange={this.chooseDeliverySetup} />
                    <label htmlFor="delivery1">Zone 1</label>
                    <p className="description">Includes Auckland central suburbs as far as Mt Wellington and selected East Auckland suburbs.</p>
                  </div>
                  <div className="radio">
                    <input id="delivery2" name="deliveryZones" type="radio" value="Zone 2" data-price="15" checked={this.props.getStore().delivery.zone === 'Zone 2'} onChange={this.chooseDeliverySetup} />
                    <label htmlFor="delivery2">Zone 2 (+$15)</label>
                    <p className="description">Includes selected West and South Auckland areas.</p>
                  </div>
                  <div className="radio">
                    <input id="delivery3" name="deliveryZones" type="radio" value="Zone 3" data-price="30" checked={this.props.getStore().delivery.zone === 'Zone 3'} onChange={this.chooseDeliverySetup} />
                    <label htmlFor="delivery3">Zone 3 (+$30)</label>
                    <p className="description">Includes North Shore as far as Silverdale and Helensville.</p>
                  </div>
                  <p><a className="terms smartyRed" onClick={this.openDeliveryModal}>View our delivery zones</a></p>
                  <p className="description">As you have not chosen one of our styling options, we will simply deliver, basic tent set up and leave you to style with your own items to create your own party look. We will come back in the morning to pack down the tents and take away.</p>
                </div>
          }

          <h4>Where did you hear about us?</h4>
          <div>
            <label>Let us know how you found us, if you like</label>
            <textarea className="hearabout" name="hearabout" value={this.props.getStore().hearabout} onChange={this.handleInputChange}></textarea>
          </div>

          <h4>Any questions or further comments, please let us know</h4>
          <div>
            <label>Comments, if you have any</label>
            <textarea className="comments" name="comments" value={this.props.getStore().comments} onChange={this.handleInputChange}></textarea>
          </div>

          <h4>You've read the rules?</h4>
          <div>
            <Checkbox
                label={'I have read and accept the Smarty Party terms and conditions'}
                price={0}
                handleCheckboxChange={this.toggleCheckbox}
                key={'termsandconditions'}
                id={'termsandconditions'}
            />
            {
              this.state.invalidTermsConditions &&
                  <div className="form-error">You must accept our terms and conditions to proceed.</div>
            }
            <p><a className="terms smartyRed" onClick={this.openModal}>View the terms and conditions</a></p>
          </div>

          <Modal
              isOpen={this.state.modalIsOpen}
              contentLabel="Terms and conditions"
              style={customModalStyles}
          >
            <div className="modal-wrapper">
              <div className="bm-cross-button">
                <span className="cross-container">
                    <span className="bm-cross left"></span>
                    <span className="bm-cross right"></span>
                </span>
                <button onClick={this.closeModal}>Close Menu</button>
              </div>
              <div className="modal-contents">
                <h3 className="title-header" dangerouslySetInnerHTML={{ __html: this.props.termsPage.title }} />

                <div dangerouslySetInnerHTML={{ __html: this.props.termsPage.content }} />
              </div>
            </div>
          </Modal>

          <Modal
              isOpen={this.state.deliveryModalIsOpen}
              contentLabel="Delivery zones"
              style={customModalStyles}
          >
            <div className="modal-wrapper">
              <div className="bm-cross-button">
                <span className="cross-container">
                    <span className="bm-cross left"></span>
                    <span className="bm-cross right"></span>
                </span>
                <button onClick={this.closeDeliveryModal}>Close Menu</button>
              </div>
              <div className="modal-contents">
                <h3>Delivery zones</h3>
                <p className="excerpt">We aim to keep our delivery and setup fees as fair as possible, merely covering our costs.</p>
                <p className="excerpt">The prices stated within our website include delivery and setup cost for our standard delivery area, Zone 1, which encompasses Auckland central suburbs as far as Mt Wellington and selected East Auckland suburbs.</p>
                <p className="excerpt">If you're in Zone 2 or Zone 3, don't worry, we still want to help, we just need to add a little bit more to our delivery fee to cover the costs.</p>
                <p className="excerpt">If you wish to make a booking but do not live within our delivery zones, please contact us directly and we will see if we are able to offer you a booking.</p>
                <Img fixed={this.props.deliveryArea.childImageSharp.fixed} title="Delivery area" alt="Delivery area" />
                <br />
                <p className="small">* Approximate boundaries only</p>
              </div>
            </div>
          </Modal>
        </div>
    )
  }
}

export default PartyStep3
